<template>
  <div class="loader-container" v-if="isLoading">
    <ip-loader />
  </div>
  <div class="user__container user-objects" v-else>
    <div class="user__body">
      <div class="wrapper__body__elem">
        <div class="wrapper__body__elem__title p-b">
          Список объектов
        </div>
        <div class="wrapper__body__elem__info no-pad">
          <div class="objects-list">
            <user-object 
              v-for="object in objects"
              :key="object.id"
              :object="object"
              :selected-objects="selectedObjects"
              :is-open="openObjectId === object.id"
              @selectObject="selectObject"
              @openObject="openObject"/>
              <div v-if="!objects.length" class="no-data">Нет обьектов</div>
          </div>
        </div>
      </div>
      <div class="wrapper__body__elem">
        <div class="wrapper__body__elem__title">
          Прикреплённые сканы
        </div>
        <div class="wrapper__body__elem__info">
        <div
          v-for="(document, index) in documents"
          :key="index">
          <vue-pdf-app
            style="height: 400px;"
            v-if="document.extension === 'pdf'"
            :pdf="documentBuffer(document)"
            :config="config" />
          <img
            v-else
            :src="documentBuffer(document)"
            :alt="'document-'+index">
        </div>
        </div>
      </div>
    </div>
    <div class="user__footer">
      <ip-select
        :options="decisions"
        label="Решение"
        placeholder="Не выбрано"
        @onSelect="selectDecision"
        :selected-item="decision"
        v-validate="{required: true}"
        v-model="decision"
        name="decision"
        :invalid="errors.has('decision')"
        open="top"
      />
      <ip-select
        :options="reasons"
        label="Резолюция"
        placeholder="Не выбрано"
        @onSelect="value => reason = value"
        :selected-item="reason"
        v-validate="{required: true}"
        v-model="reason"
        name="reason"
        :invalid="errors.has('reason')"
        open="top"
      />
      <ip-text-input
        name="comment"
        id="comment"
        v-model="comment"
        label="Комментарий"
        placeholder="Ввести..."
      />
      <ip-button
        theme="rounded"
        color="blue"
        @click="saveObjects"
        :disabled="!selectedObjects.length">
        Сохранить
      </ip-button>
    </div>
  </div>
</template>

<script>
import IpSelect from "../../components/IpSelect";
import IpTextInput from "../../components/IpTextInput";
import IpButton from "../../components/IpButton";
import IpLoader from "../../components/IpLoader";
import UserObject from "../../components/User/UserObject";
import apiRoutes from '../../consts/apiRoutes';

export default {
  name: 'user-objects',

  components: {
    IpSelect,
    IpTextInput,
    IpButton,
    IpLoader,
    UserObject
  },

  data() {
    return {
      isLoading: true,
      decisions: [
        {
          text: 'Не выбрано',
          value: ''
        },
        {
          text: 'Подтвердить',
          value: 'accepted'
        },
        {
          text: 'Отклонить',
          value: 'rejected'
        }
      ],
      reasons: [
        {
          text: 'Не выбрано',
          value: ''
        },
        {
          text: 'Данные соответствуют',
          value: 'data_match'
        },
        {
          text: 'Данные не соответствуют скану',
          value: 'data_not_match'
        },
        {
          text: 'Скан плохого качества',
          value: 'scan_quality'
        },
        {
          text: 'Не все сканы',
          value: 'not_all_scans'
        },
      ],
      decision: '',
      reason: '',
      comment: '',
      selectedObjects: [],
      openObjectId: null,
      objects: [],
      config: {
        toolbar: {
          toolbarViewerLeft: { 
            findbar: false 
          },
          toolbarViewerRight: false,
          sidebar: false
        },
        secondaryToolbar: false
      },
    };
  },

  async mounted() {
    const response = await this.axios.get(apiRoutes.clientObjects.replace('{id}', this.$route.params.id));
    this.objects = response.data;
    this.isLoading = false;
  },

  methods: {
    selectDecision(decision) {
      this.decision = decision;
      if (decision === 'rejected') {
        this.reason = '';
        return;
      }
      if (decision === 'accepted') {
        this.reason = 'data_match';
        return;
      }
    },

    async saveObjects() {
      const isValid = await this.$validator.validate();

      if (!isValid) {
        return;
      }

      this.isLoading = true;
      const data = {
        objectIds: this.selectedObjects,
        decision: this.decision,
        reason: this.reason,
        comment: this.comment
      };

      try {
        await this.axios.post(apiRoutes.updateClientObjects, data);
        this.isLoading = false;
        this.selectedObjects = [];
      } catch(error) {
        console.warn(error);
        this.isLoading = false;
      }
    },

    documentBuffer(document) {

      if (document.extension === 'pdf') {
        const buffer = new Buffer.from(document.buffer, 'base64');
        return buffer.buffer;
      }

      const buffer = new Buffer.from(document.buffer, 'base64').toString('base64');

      return `data:image/${document.extension};base64,${buffer}`;
    },

    selectObject(id) {
      if (this.selectedObjects.includes(id)) {
        this.selectedObjects = this.selectedObjects.filter(el => el !== id);
      } else {
        this.selectedObjects.push(id);
      }
    },

    openObject(id) {
      this.openObjectId = this.openObjectId === id ? null : id;
    }
  },

  computed: {
    documents() {
      if (!this.openObjectId) {
        return [];
      }

      return this.objects.find(el => el.id === this.openObjectId).documents;
    }
  }
}
</script>

<style scoped lang="scss">
.user__body{
  flex: 1 1 auto;
  height: calc(100% - 92px);
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  img{
    width: 100%;
    margin-bottom: 8px;
  }
}
.user-objects{
  .wrapper__body__elem__info.no-pad{
    padding: 0;
  }
  .wrapper__body__elem__title.p-b{
    padding-bottom: 24px;
    border-bottom: 1px solid $alto;
  }
}
</style>