<template>
  <div class="user-object">
    <div class="user-object__head">
      <div
        class="object-select"
        :class="{'selected': isSelected}"
        @click="$emit('selectObject', object.id)">
        <inline-svg
          v-if="isSelected"
          :src="require('../../assets/icons/check_24px_outlined.svg')" />
      </div>
      <div class="address">
        <div class="street">{{ address }}</div>
        <div class="district">{{ district }}</div>
      </div>
      <div class="date">{{ date }}</div>
      <div
        class="status"
        :class="objectStatus">
        <inline-svg
          v-if="object.contract"
          :src="require('../../assets/icons/done_24px_outlined.svg')"
        ></inline-svg>
        <inline-svg
          v-else
          :src="require('../../assets/icons/cached_24px_outlined.svg')"
        ></inline-svg>
        <span v-if="object.contract">
          Сдан
        </span>
        <span v-else>
          Свободен
        </span>
      </div>
      <div
        class="toggle-button"
        :class="{'open': isOpen}"
        @click="$emit('openObject', object.id)">
        <inline-svg :src="require('../../assets/icons/expand_more_24px_outlined.svg')" />
      </div>
    </div>
    <div class="user-object__body" v-if="isOpen">
      <div class="object__info">
        <div class="object__elem w50">
          <div class="wrapper__body__elem__info__title">
            Город
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ object.city && object.city.name_ru || '-' }}
          </div>
          <div class="wrapper__body__elem__info__title">
            Район
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ object.district && object.district.name_ru || '-' }}
          </div>
          <div class="wrapper__body__elem__info__title">
            Улица
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ object.street && object.street.name_ru || '-' }}
          </div>
          <div class="wrapper__body__elem__info__title">
            Дом
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ object.building || '-' }}
          </div>
          <div class="wrapper__body__elem__info__title">
            Стоимость в месяц
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ object.details.rentPrice || '-' }}
          </div>
          <div class="wrapper__body__elem__info__title">
            Валюта
          </div>
          <div class="wrapper__body__elem__info__description">
            грн
          </div>
          <div class="wrapper__body__elem__info__title">
            Срок аренды
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ rentPeriod || '-' }}
          </div>
          
        </div>
        <div class="object__elem w50">
          <div class="wrapper__body__elem__info__title">
            Тип недвижимости
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ type || '-' }}
          </div>
          <div class="wrapper__body__elem__info__title">
            Комнат
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ object.details.roomsCount || '-' }}
          </div>
          <div class="wrapper__body__elem__info__title">
            Площадь общая
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ object.details.square || '-' }} м2
          </div>
          <div class="wrapper__body__elem__info__title">
            Площадь жилая
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ object.details.squareLiving || '-' }} м2
          </div>
          <div class="wrapper__body__elem__info__title">
            Площадь кухни
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ object.details.squareKitchen || '-' }} м2
          </div>
          <div class="wrapper__body__elem__info__title">
            Этаж
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ object.details.floor || '-' }}
          </div>
          <div class="wrapper__body__elem__info__title">
            Этажность дома
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ object.details.floorCount || '-' }}
          </div>
          
        </div>
        <div class="object__elem">
          <div class="wrapper__body__elem__info__title">
            Описание
          </div>
          <div class="wrapper__body__elem__info__description">
            {{ object.details.description || '-' }}
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const rentPeriods = {
  "from_3_months": "от 3 мес",
  "from_6_months": "от 6 мес",
  "from_1_year": "от 1 года"
};

const types = {
  "apartment": "квартира",
  "house": "дом",
  "office": "офис"
};

export default {
  name: 'user-object',

  props: {
    object: {
      type: Object,
      required: true
    },

    selectedObjects: {
      type: Array,
      default: () => []
    },

    isOpen: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isSelected() {
      return this.selectedObjects.includes(this.object.id);
    },

    objectStatus() {
      return this.object.contract ? 'busy' : 'free';
    },

    date() {
      const publishDate = this.$moment(this.object.publishDate).date();
      const today = this.$moment().date();
      const yesterday = this.$moment().add(-1, 'days').date();
      if (publishDate === today) {
        return 'Опубликовано сегодня';
      }

      if (publishDate === yesterday) {
        return 'Опубликовано вчера';
      }

      return `Опубликовано ${this.$moment(this.object.publishDate).format('DD.MM.YYYY HH:mm')}`;
    },

    address() {
      if (!this.object.street) {
        return '';
      }
      return `${this.object.street.name_ru} ${this.object.building}`;
    },

    district() {
      if (!this.object.city  || !this.object.district) {
        return '';
      }

      return `${this.object.city.name_ru}, ${this.object.district.name_ru}`;
    },

    rentPeriod() {
      return rentPeriods[this.object.details.rentPeriod];
    },

    type() {
      return types[this.object.type];
    }
  }
}
</script>

<style scoped lang="scss">
.user-object{
  border-bottom: 1px solid $alto;
  &__head{
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .object-select{
      background: $white;
      border: 1px solid $silver;
      box-sizing: border-box;
      border-radius: 12px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: none;
      margin-right: 14px;
      &:hover{
        cursor: pointer;
      }
      &.selected{
        background: $scienceBlue;
      }
    }
    .toggle-button{
      cursor: pointer;
      margin-left: 8px;
        width: 24px;
        height: 24px;
      &.open{
        ::v-deep svg{
          transform: rotate(180deg);
          transform-origin: center;
        }
      }
    }
    .status{
      border-radius: 3px;
      padding: 0 7px;
      height: 26px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      flex: none;
      span{
        margin-left: 4px;
      }
      &.busy{
        background: $persianGreen;
        color: $white;
      }
      &.free{
        background: $amber;
        color: $black;
      }
    }
    .date{
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: $black;
      width: 30%;
    }
    .address{
      margin-right: 8px;
      width: 30%;
      .street{
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $black;
        margin-bottom: 4px;
      }
      .district{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: $black;
      }
    }
  }
  &__body{
    padding: 0 24px;
    .object__info{
      display: flex;
      flex-wrap: wrap;
      .object__elem{
        border-top: 1px solid $alto;
        width: 100%;
        padding: 16px 24px;
        &.w50{
          width: 50%;
          &:first-child{
            border-right: 1px solid $alto;
          }
        }
      }
    }
  }
}
</style>